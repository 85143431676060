import React from 'react';
import { Clock, DollarSign, Trash2 } from 'lucide-react';
import type { Service } from '../../lib/database.types';

interface ServiceListProps {
  services: Service[];
  onDelete: (id: string) => void;
}

export function ServiceList({ services, onDelete }: ServiceListProps) {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {services.map((service) => (
        <div
          key={service.id}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="flex-1 min-w-0">
            <div className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-gray-900">{service.name}</p>
              {service.description && (
                <p className="text-sm text-gray-500">{service.description}</p>
              )}
              <div className="mt-2 flex items-center space-x-4 text-sm text-gray-500">
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {service.duration} min
                </div>
                <div className="flex items-center">
                  <DollarSign className="h-4 w-4 mr-1" />
                  {service.price}
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={() => onDelete(service.id)}
            className="text-gray-400 hover:text-red-600"
          >
            <Trash2 className="h-5 w-5" />
          </button>
        </div>
      ))}
    </div>
  );
}