import React from 'react';
import { Clock } from 'lucide-react';
import { AppointmentListItem } from './AppointmentListItem';
import type { Appointment } from '../../lib/api/appointments';

interface AppointmentListProps {
  appointments: Appointment[];
  onStatusChange: (id: string, status: string) => void;
}

export function AppointmentList({ appointments, onStatusChange }: AppointmentListProps) {
  if (appointments.length === 0) {
    return (
      <div className="text-center py-6">
        <Clock className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-medium text-gray-900">No appointments</h3>
        <p className="mt-1 text-sm text-gray-500">
          No appointments scheduled for this day.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4 max-h-[600px] overflow-y-auto">
      {appointments.map((appointment) => (
        <AppointmentListItem
          key={appointment.id}
          appointment={appointment}
          onStatusChange={onStatusChange}
        />
      ))}
    </div>
  );
}