import type { SubscriptionTier } from '../types/subscription';

export function getSubscriptionBadgeColor(tier?: SubscriptionTier): string {
  switch (tier) {
    case 'premium':
      return 'bg-purple-100 text-purple-800 border-purple-200';
    case 'standard':
      return 'bg-blue-100 text-blue-800 border-blue-200';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-200';
  }
}