import React, { Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LandingPage } from '../pages/home/LandingPage';
import { LoginPage } from '../pages/auth/LoginPage';
import { RegisterPage } from '../pages/auth/RegisterPage';
import { CustomerPortalPage } from '../pages/portal/CustomerPortalPage';
import { DashboardPage } from '../pages/dashboard/DashboardPage';
import { AppointmentsPage } from '../pages/dashboard/AppointmentsPage';
import { ProfilePage } from '../pages/dashboard/ProfilePage';
import { StaffPage } from '../pages/dashboard/StaffPage';
import { ServicesPage } from '../pages/dashboard/ServicesPage';
import { ContactsPage } from '../pages/dashboard/ContactsPage';
import { SettingsPage } from '../pages/dashboard/SettingsPage';
import { SubscriptionPage } from '../pages/dashboard/SubscriptionPage';
import { PrivateRoute } from './PrivateRoute';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';

export function AppRoutes() {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/portal/:businessId" element={<CustomerPortalPage />} />

        {/* Protected Dashboard Routes */}
        <Route 
          path="/dashboard" 
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/appointments" 
          element={
            <PrivateRoute>
              <AppointmentsPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/profile" 
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/staff" 
          element={
            <PrivateRoute>
              <StaffPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/services" 
          element={
            <PrivateRoute>
              <ServicesPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/contacts" 
          element={
            <PrivateRoute>
              <ContactsPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/settings" 
          element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/dashboard/subscription" 
          element={
            <PrivateRoute>
              <SubscriptionPage />
            </PrivateRoute>
          } 
        />

        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
}