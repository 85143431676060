import React from 'react';
import { Menu, Bell } from 'lucide-react';
import { useAuthStore } from '../../store/auth';

interface MobileHeaderProps {
  title: string;
  onMenuClick?: () => void;
}

export function MobileHeader({ title, onMenuClick }: MobileHeaderProps) {
  const { user } = useAuthStore();

  return (
    <header className="mobile-header lg:hidden">
      <div className="flex items-center justify-between h-16 px-4">
        <div className="flex items-center">
          <button onClick={onMenuClick} className="text-gray-500">
            <Menu className="h-6 w-6" />
          </button>
          <h1 className="ml-4 text-lg font-semibold text-gray-900 truncate">
            {title}
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <button className="text-gray-500">
            <Bell className="h-6 w-6" />
          </button>
        </div>
      </div>
    </header>
  );
}