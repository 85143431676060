import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { SUBSCRIPTION_LIMITS } from '../../types/subscription';
import type { SubscriptionTier, SubscriptionUsage } from '../../types/subscription';
import { ProgressBar } from '../ui/ProgressBar';

interface SubscriptionUsagePanelProps {
  tier: SubscriptionTier;
  usage: SubscriptionUsage;
}

export function SubscriptionUsagePanel({ tier, usage }: SubscriptionUsagePanelProps) {
  const limits = SUBSCRIPTION_LIMITS[tier];
  const bookingPercentage = limits.maxBookings === Infinity ? 0 : 
    (usage.currentBookings / limits.maxBookings) * 100;
  const staffPercentage = (usage.currentStaff / limits.maxStaff) * 100;
  
  const isNearBookingLimit = bookingPercentage >= limits.warningThreshold * 100;
  const isNearStaffLimit = staffPercentage >= limits.warningThreshold * 100;

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-medium text-gray-900">Subscription Usage</h2>
        <div className="text-sm text-gray-500">
          {usage.daysRemaining} days remaining
        </div>
      </div>

      <div className="space-y-6">
        {/* Bookings Usage */}
        <div>
          <div className="flex justify-between mb-2">
            <span className="text-sm font-medium text-gray-700">
              Monthly Bookings
            </span>
            <span className="text-sm text-gray-500">
              <span className="font-medium">{usage.currentBookings}</span>
              {' / '}
              <span>{limits.maxBookings === Infinity ? '∞' : limits.maxBookings}</span>
            </span>
          </div>
          {limits.maxBookings !== Infinity && (
            <>
              <ProgressBar 
                value={bookingPercentage} 
                warning={isNearBookingLimit}
              />
              {isNearBookingLimit && (
                <div className="mt-2 flex items-center text-sm text-amber-600">
                  <AlertTriangle className="h-4 w-4 mr-1" />
                  Approaching booking limit
                </div>
              )}
            </>
          )}
        </div>

        {/* Staff Usage */}
        <div>
          <div className="flex justify-between mb-2">
            <span className="text-sm font-medium text-gray-700">
              Staff Members
            </span>
            <span className="text-sm text-gray-500">
              <span className="font-medium">{usage.currentStaff}</span>
              {' / '}
              <span>{limits.maxStaff}</span>
            </span>
          </div>
          <ProgressBar 
            value={staffPercentage} 
            warning={isNearStaffLimit}
          />
          {isNearStaffLimit && (
            <div className="mt-2 flex items-center text-sm text-amber-600">
              <AlertTriangle className="h-4 w-4 mr-1" />
              Approaching staff limit
            </div>
          )}
        </div>

        {/* Billing Period */}
        <div className="pt-4 border-t border-gray-200">
          <div className="text-sm text-gray-500">
            Billing Period: {new Date(usage.periodStart).toLocaleDateString()} - {new Date(usage.periodEnd).toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
}