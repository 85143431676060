import React, { useState, useEffect } from 'react';
import { api } from '../../lib/api';
import { Button } from '../ui/Button';
import type { BusinessHours } from '../../lib/api';

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const DEFAULT_HOURS: Omit<BusinessHours, 'id' | 'business_id'>[] = DAYS.map((_, index) => ({
  day_of_week: index,
  start_time: '09:00',
  end_time: '17:00',
  is_closed: index === 0 || index === 6, // Closed on weekends by default
}));

export function BusinessHours() {
  const [hours, setHours] = useState<BusinessHours[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    loadBusinessHours();
  }, []);

  const loadBusinessHours = async () => {
    try {
      const data = await api.businessHours.list();
      if (data.length > 0) {
        setHours(data);
      } else {
        setHours(DEFAULT_HOURS as BusinessHours[]);
      }
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    setError('');

    try {
      await api.businessHours.upsert(hours);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateHours = (index: number, updates: Partial<BusinessHours>) => {
    setHours(current =>
      current.map((hour, i) =>
        i === index ? { ...hour, ...updates } : hour
      )
    );
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {hours.map((hour, index) => (
          <div key={hour.day_of_week} className="flex items-center space-x-4">
            <div className="w-32">
              <span className="text-sm font-medium text-gray-900">
                {DAYS[hour.day_of_week]}
              </span>
            </div>
            
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={!hour.is_closed}
                onChange={(e) => updateHours(index, { is_closed: !e.target.checked })}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-sm text-gray-600">Open</span>
            </label>

            {!hour.is_closed && (
              <>
                <input
                  type="time"
                  value={hour.start_time}
                  onChange={(e) => updateHours(index, { start_time: e.target.value })}
                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                <span className="text-gray-500">to</span>
                <input
                  type="time"
                  value={hour.end_time}
                  onChange={(e) => updateHours(index, { end_time: e.target.value })}
                  className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </>
            )}
          </div>
        ))}
      </div>

      <div className="pt-4">
        <Button onClick={handleSave} isLoading={isLoading}>
          Save Business Hours
        </Button>
      </div>
    </div>
  );
}