import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { AppointmentCalendar } from '../../components/appointments/AppointmentCalendar';
import { AppointmentList } from '../../components/appointments/AppointmentList';
import { AppointmentForm } from '../../components/appointments/AppointmentForm';
import { Button } from '../../components/ui/Button';
import { Modal } from '../../components/ui/Modal';
import { api } from '../../lib/api';
import type { Appointment } from '../../lib/api';

export function AppointmentsPage() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    loadAppointments();
  }, [selectedDate]);

  const loadAppointments = async () => {
    try {
      const data = await api.appointments.list(selectedDate);
      setAppointments(data);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await api.appointments.updateStatus(id, status);
      await loadAppointments();
    } catch (err: any) {
      setError(err.message);
    }
  };

  const handleAppointmentSuccess = () => {
    setIsModalOpen(false);
    loadAppointments();
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Appointments</h1>
            <p className="mt-1 text-sm text-gray-500">
              Manage your appointments and bookings
            </p>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>
            New Appointment
          </Button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2 bg-white rounded-lg shadow">
            <AppointmentCalendar
              appointments={appointments}
              selectedDate={selectedDate}
              onDateSelect={setSelectedDate}
            />
          </div>
          
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              {appointments.length === 0 
                ? "No appointments for this day"
                : `${appointments.length} appointment${appointments.length === 1 ? '' : 's'}`
              }
            </h2>
            <AppointmentList
              appointments={appointments}
              onStatusChange={handleStatusChange}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="New Appointment"
      >
        <AppointmentForm
          selectedDate={selectedDate}
          onSuccess={handleAppointmentSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </DashboardLayout>
  );
}