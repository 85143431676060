import React, { useState } from 'react';
import { Modal } from '../ui/Modal';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { validateEmail, validatePhone } from '../../utils/validation';
import type { Staff } from '../../lib/types';

interface StaffEditModalProps {
  staff: Staff;
  onSave: (data: Partial<Staff>) => void;
  onClose: () => void;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  role: string;
  qualifications: string;
  schedule: {
    [key: string]: {
      start: string;
      end: string;
      enabled: boolean;
    };
  };
}

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export function StaffEditModal({ staff, onSave, onClose }: StaffEditModalProps) {
  const [formData, setFormData] = useState<FormData>({
    name: staff.name,
    email: staff.email,
    phone: staff.phone || '',
    role: staff.role,
    qualifications: staff.qualifications || '',
    schedule: staff.working_hours || DAYS.reduce((acc, day) => ({
      ...acc,
      [day]: { start: '09:00', end: '17:00', enabled: true }
    }), {})
  });

  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
  const [isLoading, setIsLoading] = useState(false);

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof FormData, string>> = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    const emailValidation = validateEmail(formData.email);
    if (!emailValidation.isValid) {
      newErrors.email = emailValidation.message || 'Invalid email';
    }

    if (formData.phone) {
      const phoneValidation = validatePhone(formData.phone);
      if (!phoneValidation.isValid) {
        newErrors.phone = phoneValidation.message || 'Invalid phone number';
      }
    }

    if (!formData.role.trim()) {
      newErrors.role = 'Role is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      await onSave(formData);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Edit Staff Member"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <Input
          label="Full Name"
          value={formData.name}
          onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
          error={errors.name}
          required
        />

        <Input
          label="Email"
          type="email"
          value={formData.email}
          onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
          error={errors.email}
          required
        />

        <Input
          label="Phone"
          type="tel"
          value={formData.phone}
          onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
          error={errors.phone}
        />

        <Input
          label="Role"
          value={formData.role}
          onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
          error={errors.role}
          required
        />

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Working Hours
          </label>
          {DAYS.map((day) => (
            <div key={day} className="flex items-center space-x-4">
              <input
                type="checkbox"
                checked={formData.schedule[day]?.enabled}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  schedule: {
                    ...prev.schedule,
                    [day]: {
                      ...prev.schedule[day],
                      enabled: e.target.checked
                    }
                  }
                }))}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <span className="text-sm text-gray-700 w-24">{day}</span>
              <input
                type="time"
                value={formData.schedule[day]?.start}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  schedule: {
                    ...prev.schedule,
                    [day]: {
                      ...prev.schedule[day],
                      start: e.target.value
                    }
                  }
                }))}
                disabled={!formData.schedule[day]?.enabled}
                className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <span className="text-sm text-gray-500">to</span>
              <input
                type="time"
                value={formData.schedule[day]?.end}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  schedule: {
                    ...prev.schedule,
                    [day]: {
                      ...prev.schedule[day],
                      end: e.target.value
                    }
                  }
                }))}
                disabled={!formData.schedule[day]?.enabled}
                className="block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-4">
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" isLoading={isLoading}>
            Save Changes
          </Button>
        </div>
      </form>
    </Modal>
  );
}