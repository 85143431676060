import React, { useState } from 'react';
import { Search, Mail, Phone, Calendar, Edit2, Trash2 } from 'lucide-react';
import { format } from 'date-fns';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { StaffEditModal } from './StaffEditModal';
import type { Staff } from '../../lib/types';

interface StaffListProps {
  staff: Staff[];
  onDelete: (id: string) => Promise<void>;
  onUpdate: (id: string, data: Partial<Staff>) => Promise<void>;
}

export function StaffList({ staff, onDelete, onUpdate }: StaffListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingStaff, setEditingStaff] = useState<Staff | null>(null);
  const [error, setError] = useState('');

  const filteredStaff = staff.filter(member => 
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = (member: Staff) => {
    setEditingStaff(member);
    setError('');
  };

  const handleUpdate = async (data: Partial<Staff>) => {
    if (!editingStaff) return;
    
    try {
      await onUpdate(editingStaff.id, data);
      setEditingStaff(null);
    } catch (err: any) {
      console.error('Failed to update staff member:', err);
      setError(err.message || 'Failed to update staff member');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await onDelete(id);
    } catch (err: any) {
      console.error('Failed to delete staff member:', err);
      setError(err.message || 'Failed to delete staff member');
    }
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
          {error}
        </div>
      )}

      {/* Search Bar */}
      <div className="relative">
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
        <Input
          type="search"
          placeholder="Search staff members..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="pl-10"
        />
      </div>

      {/* Staff List */}
      <div className="space-y-4">
        {filteredStaff.map((member) => (
          <div
            key={member.id}
            className="bg-white rounded-lg border border-gray-200 p-4 hover:border-gray-300 transition-colors"
          >
            <div className="flex justify-between items-start">
              <div className="flex items-start space-x-4">
                {/* Avatar or Initials */}
                <div className="w-12 h-12 rounded-full bg-indigo-100 flex items-center justify-center text-indigo-600 font-medium text-lg">
                  {member.name.split(' ').map(n => n[0]).join('')}
                </div>

                {/* Staff Info */}
                <div>
                  <h3 className="font-medium text-gray-900">{member.name}</h3>
                  <p className="text-sm text-gray-500">{member.role}</p>
                  
                  <div className="mt-2 space-y-1">
                    <div className="flex items-center text-sm text-gray-500">
                      <Mail className="h-4 w-4 mr-2" />
                      {member.email}
                    </div>
                    {member.phone && (
                      <div className="flex items-center text-sm text-gray-500">
                        <Phone className="h-4 w-4 mr-2" />
                        {member.phone}
                      </div>
                    )}
                    <div className="flex items-center text-sm text-gray-500">
                      <Calendar className="h-4 w-4 mr-2" />
                      Joined {format(new Date(member.created_at), 'MMM d, yyyy')}
                    </div>
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className="flex space-x-2">
                <Button
                  variant="secondary"
                  onClick={() => handleEdit(member)}
                >
                  <Edit2 className="h-4 w-4" />
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => handleDelete(member.id)}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Edit Modal */}
      {editingStaff && (
        <StaffEditModal
          staff={editingStaff}
          onSave={handleUpdate}
          onClose={() => setEditingStaff(null)}
        />
      )}
    </div>
  );
}