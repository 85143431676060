import React from 'react';
import { Check, Crown } from 'lucide-react';
import { Button } from '../ui/Button';
import type { SubscriptionTier } from '../../types/subscription';

interface SubscriptionPlansPanelProps {
  currentTier: SubscriptionTier;
  onUpgrade: (plan: 'standard' | 'premium') => void;
}

const PLAN_FEATURES = {
  basic: [
    '100 bookings per month',
    'Up to 5 staff members',
    'Basic analytics',
    'Email notifications',
    'Online booking widget',
  ],
  standard: [
    'Unlimited bookings',
    'Up to 15 staff members',
    'Advanced analytics',
    'SMS notifications',
    'Custom booking page',
    'Integration with Google Calendar',
    'Priority support',
  ],
  premium: [
    'Everything in Standard',
    'Up to 50 staff members',
    'White-label solution',
    'API access',
    'Dedicated account manager',
    'Custom integration support',
    '24/7 phone support',
  ],
};

export function SubscriptionPlansPanel({ currentTier, onUpgrade }: SubscriptionPlansPanelProps) {
  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-medium text-gray-900">Available Plans</h2>
        <Crown className="h-5 w-5 text-indigo-600" />
      </div>

      <div className="space-y-6">
        {/* Current Plan */}
        <div className="border-b border-gray-200 pb-6">
          <div className="flex items-center justify-between mb-4">
            <div>
              <h3 className="text-base font-medium text-gray-900">
                Current Plan: {currentTier.charAt(0).toUpperCase() + currentTier.slice(1)}
              </h3>
              <p className="text-sm text-gray-500">
                {currentTier === 'basic' ? 'Upgrade to unlock more features' : 'Your current subscription'}
              </p>
            </div>
          </div>
          <div className="space-y-2">
            {PLAN_FEATURES[currentTier].map((feature) => (
              <div key={feature} className="flex items-center text-sm text-gray-600">
                <Check className="h-4 w-4 text-green-500 mr-2" />
                {feature}
              </div>
            ))}
          </div>
        </div>

        {/* Upgrade Options */}
        {currentTier !== 'premium' && (
          <div className="space-y-4">
            <h3 className="text-base font-medium text-gray-900">Upgrade Options</h3>
            
            {currentTier === 'basic' && (
              <div className="border rounded-lg p-4">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h4 className="text-base font-medium text-gray-900">Standard Plan</h4>
                    <p className="text-sm text-gray-500">$49.99/month</p>
                  </div>
                  <Button onClick={() => onUpgrade('standard')}>
                    Upgrade to Standard
                  </Button>
                </div>
                <div className="space-y-2">
                  {PLAN_FEATURES.standard.map((feature) => (
                    <div key={feature} className="flex items-center text-sm text-gray-600">
                      <Check className="h-4 w-4 text-green-500 mr-2" />
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="border rounded-lg p-4 bg-gradient-to-r from-indigo-50 to-purple-50">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h4 className="text-base font-medium text-gray-900">Premium Plan</h4>
                  <p className="text-sm text-gray-500">$99.99/month</p>
                </div>
                <Button onClick={() => onUpgrade('premium')}>
                  Upgrade to Premium
                </Button>
              </div>
              <div className="space-y-2">
                {PLAN_FEATURES.premium.map((feature) => (
                  <div key={feature} className="flex items-center text-sm text-gray-600">
                    <Check className="h-4 w-4 text-green-500 mr-2" />
                    {feature}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}