import React from 'react';

interface ProgressBarProps {
  value: number;
  warning?: boolean;
}

export function ProgressBar({ value, warning }: ProgressBarProps) {
  return (
    <div className="w-full bg-gray-200 rounded-full h-2">
      <div
        className={`h-2 rounded-full ${
          warning ? 'bg-amber-500' : 'bg-indigo-600'
        }`}
        style={{ width: `${Math.min(value, 100)}%` }}
      />
    </div>
  );
}