import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Calendar, Users, Settings, Briefcase, UserCircle, Phone } from 'lucide-react';

export function MobileNavigation() {
  const location = useLocation();
  
  const navigation = [
    { name: 'Calendar', href: '/dashboard/appointments', icon: Calendar },
    { name: 'Staff', href: '/dashboard/staff', icon: Users },
    { name: 'Services', href: '/dashboard/services', icon: Briefcase },
    { name: 'More', href: '/dashboard/settings', icon: Settings },
  ];

  return (
    <nav className="mobile-nav lg:hidden">
      <div className="mobile-nav-menu">
        {navigation.map((item) => {
          const isActive = location.pathname === item.href;
          const Icon = item.icon;
          
          return (
            <Link
              key={item.name}
              to={item.href}
              className={`mobile-nav-item ${
                isActive ? 'text-indigo-600' : 'text-gray-600 hover:text-gray-900'
              }`}
            >
              <Icon className="mobile-nav-icon" />
              <span>{item.name}</span>
            </Link>
          );
        })}
      </div>
    </nav>
  );
}