import React, { useState } from 'react';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { api } from '../../lib/api';
import { validateSubscriptionLimits } from '../../lib/api/subscription';
import { AlertTriangle } from 'lucide-react';

interface StaffFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

interface FormData {
  name: string;
  email: string;
  phone: string;
  role: string;
}

export function StaffForm({ onSuccess, onCancel }: StaffFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    phone: '',
    role: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Check subscription limits before creating staff member
      const { allowed, message } = await validateSubscriptionLimits('staff');
      if (!allowed) {
        setError(message || 'Cannot add more staff members with current plan');
        return;
      }

      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      await api.staff.create({
        ...formData,
        business_id: business.id,
        working_hours: {},
      });
      
      onSuccess();
    } catch (err: any) {
      console.error('Staff creation error:', err);
      setError(err.message || 'Failed to create staff member');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 rounded-md p-4">
          <div className="flex">
            <AlertTriangle className="h-5 w-5 text-red-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Unable to Add Staff Member
              </h3>
              <p className="text-sm text-red-700 mt-1">
                {error}
              </p>
              {error.includes('limit') && (
                <div className="mt-2">
                  <a 
                    href="/dashboard/subscription" 
                    className="text-sm font-medium text-red-700 hover:text-red-600"
                  >
                    View Subscription Plans →
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <Input
        label="Name"
        required
        value={formData.name}
        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
      />

      <Input
        label="Email"
        type="email"
        required
        value={formData.email}
        onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
      />

      <Input
        label="Phone"
        type="tel"
        value={formData.phone}
        onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
      />

      <Input
        label="Role"
        required
        value={formData.role}
        onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
      />

      <div className="flex justify-end space-x-4">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" isLoading={isLoading}>
          Add Staff Member
        </Button>
      </div>
    </form>
  );
}