import { supabase } from '../supabase';
import { startOfMonth, endOfMonth, differenceInDays } from 'date-fns';
import { SUBSCRIPTION_LIMITS } from '../../types/subscription';
import type { SubscriptionUsage, SubscriptionTier } from '../../types/subscription';

export async function fetchSubscriptionUsage(): Promise<SubscriptionUsage> {
  const now = new Date();
  const periodStart = startOfMonth(now);
  const periodEnd = endOfMonth(now);

  try {
    // First get the business ID for the current user
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    // Get business details
    const { data: business, error: businessError } = await supabase
      .from('businesses')
      .select('id, subscription_period_end')
      .eq('auth_user_id', user.id)
      .single();

    if (businessError) throw businessError;
    if (!business) throw new Error('Business not found');

    // Get current month's bookings
    const { data: bookings, error: bookingsError } = await supabase
      .from('appointments')
      .select('id')
      .eq('business_id', business.id)
      .gte('start_time', periodStart.toISOString())
      .lte('end_time', periodEnd.toISOString());

    if (bookingsError) throw bookingsError;

    // Get active staff count
    const { data: staff, error: staffError } = await supabase
      .from('staff')
      .select('id')
      .eq('business_id', business.id)
      .eq('status', 'active');

    if (staffError) throw staffError;

    const periodEndDate = business.subscription_period_end ? 
      new Date(business.subscription_period_end) : 
      endOfMonth(now);

    return {
      currentBookings: bookings?.length || 0,
      currentStaff: staff?.length || 0,
      daysRemaining: differenceInDays(periodEndDate, now),
      periodStart: periodStart.toISOString(),
      periodEnd: periodEndDate.toISOString()
    };
  } catch (error) {
    console.error('Error fetching subscription usage:', error);
    throw error;
  }
}

export async function validateSubscriptionLimits(
  action: 'booking' | 'staff'
): Promise<{ allowed: boolean; message?: string }> {
  try {
    // Get the current user's business
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('Not authenticated');

    const { data: business } = await supabase
      .from('businesses')
      .select('id, subscription_tier')
      .eq('auth_user_id', user.id)
      .single();

    if (!business) throw new Error('Business not found');

    const tier = business.subscription_tier as SubscriptionTier;
    const usage = await fetchSubscriptionUsage();
    const limits = SUBSCRIPTION_LIMITS[tier];

    if (action === 'booking' && limits.maxBookings !== Infinity && usage.currentBookings >= limits.maxBookings) {
      return {
        allowed: false,
        message: 'Monthly booking limit reached. Please upgrade your plan to continue.'
      };
    }

    if (action === 'staff' && usage.currentStaff >= limits.maxStaff) {
      return {
        allowed: false,
        message: 'Staff member limit reached. Please upgrade your plan to add more staff.'
      };
    }

    return { allowed: true };
  } catch (error) {
    console.error('Error validating subscription limits:', error);
    throw error;
  }
}