import React, { useState, useEffect } from 'react';
import { format, addMinutes, parse } from 'date-fns';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { api } from '../../lib/api';
import { validateEmail, validatePhone } from '../../utils/validation';
import type { Staff, Service } from '../../lib/types';

interface AppointmentFormProps {
  selectedDate: Date;
  onSuccess: () => void;
  onCancel: () => void;
}

interface FormData {
  customerName: string;
  customerEmail: string;
  customerPhone: string;
  serviceId: string;
  staffId: string;
  date: string;
  time: string;
  notes: string;
}

export function AppointmentForm({ selectedDate, onSuccess, onCancel }: AppointmentFormProps) {
  const [services, setServices] = useState<Service[]>([]);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState<FormData>({
    customerName: '',
    customerEmail: '',
    customerPhone: '',
    serviceId: '',
    staffId: '',
    date: format(selectedDate, 'yyyy-MM-dd'),
    time: '09:00',
    notes: '',
  });

  useEffect(() => {
    loadServicesAndStaff();
  }, []);

  const loadServicesAndStaff = async () => {
    try {
      const [servicesData, staffData] = await Promise.all([
        api.services.list(),
        api.staff.list()
      ]);
      setServices(servicesData);
      setStaff(staffData);
    } catch (err: any) {
      console.error('Failed to load services and staff:', err);
      setError('Failed to load required data. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      // Get business
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      // Create or get customer
      const customer = await api.customers.createOrGet({
        business_id: business.id,
        name: formData.customerName.trim(),
        email: formData.customerEmail.trim().toLowerCase(),
        phone: formData.customerPhone.trim()
      });

      // Get service for duration
      const service = services.find(s => s.id === formData.serviceId);
      if (!service) throw new Error('Service not found');

      // Calculate appointment times
      const startTime = parse(`${formData.date} ${formData.time}`, 'yyyy-MM-dd HH:mm', new Date());
      const endTime = addMinutes(startTime, service.duration);

      // Create appointment
      await api.appointments.create({
        business_id: business.id,
        customers_id: customer.id,
        services_id: formData.serviceId,
        staff_id: formData.staffId,
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        notes: formData.notes.trim(),
        status: 'pending'
      });

      onSuccess();
    } catch (err: any) {
      console.error('Appointment creation error:', err);
      setError(err.message || 'Failed to create appointment. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
          {error}
        </div>
      )}

      <Input
        label="Customer Name"
        required
        value={formData.customerName}
        onChange={(e) => setFormData(prev => ({ ...prev, customerName: e.target.value }))}
      />

      <Input
        label="Email"
        type="email"
        required
        value={formData.customerEmail}
        onChange={(e) => setFormData(prev => ({ ...prev, customerEmail: e.target.value }))}
      />

      <Input
        label="Phone"
        type="tel"
        required
        value={formData.customerPhone}
        onChange={(e) => setFormData(prev => ({ ...prev, customerPhone: e.target.value }))}
      />

      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          Service
        </label>
        <select
          required
          value={formData.serviceId}
          onChange={(e) => setFormData(prev => ({ ...prev, serviceId: e.target.value }))}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Select a service</option>
          {services.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name} - ${service.price} ({service.duration} min)
            </option>
          ))}
        </select>
      </div>

      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          Staff Member
        </label>
        <select
          required
          value={formData.staffId}
          onChange={(e) => setFormData(prev => ({ ...prev, staffId: e.target.value }))}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        >
          <option value="">Select a staff member</option>
          {staff.map((member) => (
            <option key={member.id} value={member.id}>
              {member.name}
            </option>
          ))}
        </select>
      </div>

      <Input
        label="Date"
        type="date"
        required
        min={format(new Date(), 'yyyy-MM-dd')}
        value={formData.date}
        onChange={(e) => setFormData(prev => ({ ...prev, date: e.target.value }))}
      />

      <Input
        label="Time"
        type="time"
        required
        value={formData.time}
        onChange={(e) => setFormData(prev => ({ ...prev, time: e.target.value }))}
      />

      <div className="space-y-1">
        <label className="block text-sm font-medium text-gray-700">
          Notes
        </label>
        <textarea
          value={formData.notes}
          onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
          rows={3}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        />
      </div>

      <div className="flex justify-end space-x-4">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" isLoading={isLoading}>
          Create Appointment
        </Button>
      </div>
    </form>
  );
}