import React from 'react';
import { Crown } from 'lucide-react';
import { getSubscriptionBadgeColor } from '../../utils/subscription';
import type { SubscriptionTier } from '../../types/subscription';

interface SubscriptionBadgeProps {
  tier?: SubscriptionTier;
}

export function SubscriptionBadge({ tier }: SubscriptionBadgeProps) {
  const displayTier = tier ? 
    tier.charAt(0).toUpperCase() + tier.slice(1) : 
    'Basic';

  return (
    <div className={`flex items-center px-3 py-1 rounded-full border text-sm font-medium ${getSubscriptionBadgeColor(tier)}`}>
      <Crown className="h-4 w-4 mr-1" />
      {displayTier}
    </div>
  );
}