import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function validateEmail(email: string): { isValid: boolean; message?: string } {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  // Basic format validation
  if (!emailRegex.test(email)) {
    return { isValid: false, message: 'Please enter a valid email address' };
  }

  // Check for common typos in popular domains
  const commonDomains = {
    'gmial.com': 'gmail.com',
    'gmal.com': 'gmail.com',
    'gamil.com': 'gmail.com',
    'hotmal.com': 'hotmail.com',
    'yaho.com': 'yahoo.com',
    'outloo.com': 'outlook.com'
  };

  const [username, domain] = email.split('@');
  const suggestedDomain = commonDomains[domain as keyof typeof commonDomains];
  
  if (suggestedDomain) {
    return { 
      isValid: false, 
      message: `Did you mean ${username}@${suggestedDomain}?` 
    };
  }

  return { isValid: true };
}

export function validatePhone(phone: string, countryCode: string = 'IL'): { 
  isValid: boolean; 
  formattedNumber?: string;
  message?: string 
} {
  try {
    // Parse phone number with country code
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode);
    
    if (!phoneNumber) {
      return { 
        isValid: false, 
        message: 'Please enter a valid phone number' 
      };
    }

    // Check if phone number is valid for the country
    if (!phoneNumber.isValid()) {
      return { 
        isValid: false, 
        message: 'Invalid phone number format for your region' 
      };
    }

    // Return E.164 formatted number for storage
    return { 
      isValid: true, 
      formattedNumber: phoneNumber.format('E.164') 
    };
  } catch (error) {
    return { 
      isValid: false, 
      message: 'Unable to validate phone number' 
    };
  }
}

export function sanitizeInput(input: string): string {
  // Remove any HTML tags
  const withoutTags = input.replace(/<[^>]*>/g, '');
  
  // Remove special characters but keep basic punctuation
  const sanitized = withoutTags.replace(/[^\w\s.,!?@-]/g, '');
  
  // Trim whitespace
  return sanitized.trim();
}