import React from 'react';
import { format } from 'date-fns';
import { Mail, Phone, Calendar, Users } from 'lucide-react';
import type { Customer } from '../../lib/api';

interface ContactListProps {
  contacts: Customer[];
  onRefresh: () => void;
}

export function ContactList({ contacts, onRefresh }: ContactListProps) {
  if (contacts.length === 0) {
    return (
      <div className="bg-white shadow rounded-lg">
        <div className="p-6">
          <div className="flex flex-col items-center justify-center py-12">
            <Users className="h-12 w-12 text-gray-400" />
            <p className="mt-2 text-sm text-gray-500">No contacts yet</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <ul role="list" className="divide-y divide-gray-200">
        {contacts.map((contact) => (
          <li key={contact.id} className="p-6 hover:bg-gray-50">
            <div className="flex items-center justify-between">
              <div className="flex-1 min-w-0">
                <h3 className="text-lg font-medium text-gray-900 truncate">
                  {contact.name}
                </h3>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6">
                  {contact.email && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <Mail className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {contact.email}
                    </div>
                  )}
                  {contact.phone && (
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <Phone className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {contact.phone}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {contact.appointments && contact.appointments.length > 0 && (
              <div className="mt-4 border-t border-gray-200 pt-4">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Recent Appointments</h4>
                <div className="space-y-2">
                  {contact.appointments.slice(0, 3).map((apt: any) => (
                    <div key={apt.id} className="flex items-center text-sm text-gray-500">
                      <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      <span>{format(new Date(apt.start_time), 'MMM d, yyyy')}</span>
                      <span className="mx-1">·</span>
                      <span>{apt.services?.name}</span>
                      <span className="mx-1">·</span>
                      <span className={`
                        px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${getStatusColor(apt.status)}
                      `}>
                        {apt.status}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

function getStatusColor(status: string): string {
  switch (status) {
    case 'confirmed':
      return 'bg-green-100 text-green-800';
    case 'pending':
      return 'bg-yellow-100 text-yellow-800';
    case 'cancelled':
      return 'bg-red-100 text-red-800';
    case 'completed':
      return 'bg-blue-100 text-blue-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}