import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { Briefcase } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import { Modal } from '../../components/ui/Modal';
import { ServiceForm } from '../../components/ui/ServiceForm';
import { ServiceList } from '../../components/services/ServiceList';
import { api } from '../../lib/api';
import type { Service } from '../../lib/database.types';

export function ServicesPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadServices = async () => {
    try {
      const data = await api.services.list();
      setServices(data);
    } catch (err: any) {
      setError(err.message || 'Failed to load services');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadServices();
  }, []);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this service?')) return;
    
    try {
      await api.services.delete(id);
      setServices(services.filter(service => service.id !== id));
    } catch (err: any) {
      setError(err.message || 'Failed to delete service');
    }
  };

  const handleSuccess = () => {
    setIsModalOpen(false);
    loadServices();
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Services</h1>
            <p className="mt-1 text-sm text-gray-500">
              Manage your service offerings and pricing.
            </p>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>Add Service</Button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
            {error}
          </div>
        )}

        {services.length === 0 ? (
          <div className="bg-white shadow rounded-lg">
            <div className="p-6">
              <div className="flex flex-col items-center justify-center py-12">
                <Briefcase className="h-12 w-12 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">No services added yet</p>
                <Button variant="secondary" className="mt-4" onClick={() => setIsModalOpen(true)}>
                  Add Your First Service
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <ServiceList services={services} onDelete={handleDelete} />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Service"
      >
        <ServiceForm
          onSuccess={handleSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </DashboardLayout>
  );
}