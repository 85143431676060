import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { ContactList } from '../../components/contacts/ContactList';
import { api } from '../../lib/api';
import type { Customer } from '../../lib/api';

export function ContactsPage() {
  const [contacts, setContacts] = useState<Customer[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = async () => {
    try {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error: contactsError } = await api.supabase
        .from('customers')
        .select(`
          *,
          appointments (
            id,
            start_time,
            status,
            services (name)
          )
        `)
        .eq('business_id', business.id)
        .order('name');

      if (contactsError) throw contactsError;
      setContacts(data || []);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Contacts</h1>
          <p className="mt-1 text-sm text-gray-500">
            View and manage your customer contacts.
          </p>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
            {error}
          </div>
        )}

        <ContactList contacts={contacts} onRefresh={loadContacts} />
      </div>
    </DashboardLayout>
  );
}