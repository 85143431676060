import React from 'react';
import { Check } from 'lucide-react';

const plans = [
  {
    name: 'Basic',
    price: '$19.99',
    period: '/month',
    description: 'Perfect for small businesses just getting started',
    features: [
      '100 bookings per month',
      'Up to 5 staff members',
      'Basic analytics',
      'Email notifications',
      'Online booking widget',
    ],
    stripeLink: 'https://buy.stripe.com/dR6eXg93J08rfNScNc?redirect_url=https://appointflex.com/register?plan=basic',
    popular: false,
  },
  {
    name: 'Standard',
    price: '$49.99',
    period: '/month',
    description: 'Great for growing businesses',
    features: [
      'Unlimited bookings',
      'Up to 15 staff members',
      'Advanced analytics',
      'SMS notifications',
      'Custom booking page',
      'Integration with Google Calendar',
      'Priority support',
    ],
    stripeLink: 'https://buy.stripe.com/28obL44Ntg7pbxC9B1?redirect_url=https://appointflex.com/register?plan=standard',
    popular: true,
  },
  {
    name: 'Premium',
    price: '$99.99',
    period: '/month',
    description: 'For businesses that need the ultimate solution',
    features: [
      'Everything in Standard',
      'Up to 50 staff members',
      'White-label solution',
      'API access',
      'Dedicated account manager',
      'Custom integration support',
      '24/7 phone support',
    ],
    stripeLink: 'https://buy.stripe.com/eVa02mbbRbR945abJa?redirect_url=https://appointflex.com/register?plan=premium',
    popular: false,
  },
];

export function PricingSection() {
  const handlePlanSelection = (stripeLink: string) => {
    // Open Stripe payment link in new tab
    window.open(stripeLink, '_blank');
  };

  return (
    <div id="pricing" className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Choose the perfect plan for your business
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Simple, transparent pricing that grows with your business. All plans include a 14-day free trial.
          </p>
        </div>

        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-3">
          {plans.map((plan, planIdx) => (
            <div
              key={plan.name}
              className={`relative rounded-3xl p-8 ring-1 ring-gray-200 ${
                plan.popular
                  ? 'bg-gray-900 text-white shadow-xl lg:z-10 lg:scale-105'
                  : 'bg-white lg:scale-100'
              }`}
            >
              {plan.popular && (
                <span className="absolute -top-4 left-1/2 -translate-x-1/2 rounded-full bg-indigo-600 px-4 py-1 text-sm font-semibold text-white">
                  Most popular
                </span>
              )}
              <div className="flex flex-col">
                <div className="flex items-center justify-between gap-x-4">
                  <h3 className={`text-lg font-semibold leading-8 ${plan.popular ? 'text-white' : 'text-gray-900'}`}>
                    {plan.name}
                  </h3>
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-300">{plan.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className={`text-4xl font-bold tracking-tight ${plan.popular ? 'text-white' : 'text-gray-900'}`}>
                    {plan.price}
                  </span>
                  <span className={`text-sm font-semibold leading-6 ${plan.popular ? 'text-gray-300' : 'text-gray-600'}`}>
                    {plan.period}
                  </span>
                </p>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <Check className={`h-6 w-5 flex-none ${plan.popular ? 'text-indigo-400' : 'text-indigo-600'}`} />
                      <span className={plan.popular ? 'text-gray-300' : 'text-gray-600'}>{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handlePlanSelection(plan.stripeLink)}
                  className={`mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
                    plan.popular
                      ? 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600'
                      : 'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600'
                  }`}
                >
                  Get Started with {plan.name}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}