import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Calendar,
  Users,
  Settings,
  Briefcase,
  UserCircle,
  Phone,
  CalendarDays,
  Crown
} from 'lucide-react';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: Calendar },
  { name: 'Appointments', href: '/dashboard/appointments', icon: CalendarDays },
  { name: 'Staff', href: '/dashboard/staff', icon: Users },
  { name: 'Services', href: '/dashboard/services', icon: Briefcase },
  { name: 'Contacts', href: '/dashboard/contacts', icon: Phone },
  { name: 'Profile', href: '/dashboard/profile', icon: UserCircle },
  { name: 'Subscription', href: '/dashboard/subscription', icon: Crown },
  { name: 'Settings', href: '/dashboard/settings', icon: Settings },
];

export function DashboardSidebar() {
  const location = useLocation();

  return (
    <div className="fixed inset-y-0 left-0 z-40 w-64 bg-white border-r border-gray-200 pt-16">
      <div className="h-full px-3 py-4 overflow-y-auto">
        <ul className="space-y-2">
          {navigation.map((item) => {
            const isActive = location.pathname === item.href;
            return (
              <li key={item.name}>
                <Link
                  to={item.href}
                  className={`
                    flex items-center p-2 text-base font-normal rounded-lg
                    ${isActive 
                      ? 'bg-gray-100 text-indigo-600' 
                      : 'text-gray-900 hover:bg-gray-100'
                    }
                  `}
                >
                  <item.icon className={`
                    w-6 h-6 transition duration-75
                    ${isActive ? 'text-indigo-600' : 'text-gray-500'}
                  `} />
                  <span className="ml-3">{item.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}