import React from 'react';
import { Bell, Settings } from 'lucide-react';
import { useAuthStore } from '../../store/auth';
import { Link } from 'react-router-dom';
import { SubscriptionBadge } from '../subscription/SubscriptionBadge';

export function DashboardHeader() {
  const { user } = useAuthStore();

  return (
    <header className="fixed w-full bg-white shadow-sm z-40">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between items-center">
          <div className="flex items-center gap-x-4">
            <span className="text-xl font-semibold text-gray-900">
              {user?.business_name}
            </span>
            <SubscriptionBadge tier={user?.subscription_tier} />
          </div>
          
          <div className="flex items-center gap-x-4">
            {user?.id && (
              <Link 
                to={`/portal/${user.id}`}
                target="_blank"
                className="text-sm font-medium text-gray-700 hover:text-gray-900"
              >
                Customer Portal
              </Link>
            )}
            <button className="text-gray-500 hover:text-gray-700">
              <Bell className="h-6 w-6" />
            </button>
            <Link to="/dashboard/settings" className="text-gray-500 hover:text-gray-700">
              <Settings className="h-6 w-6" />
            </Link>
            <button
              onClick={() => useAuthStore.getState().signOut()}
              className="text-sm font-medium text-gray-700 hover:text-gray-900"
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}