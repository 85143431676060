import React from 'react';
import { format } from 'date-fns';
import { User, Briefcase } from 'lucide-react';
import { Button } from '../ui/Button';
import { getStatusBadgeColor } from './utils';
import type { Appointment } from '../../lib/api/appointments';

interface AppointmentListItemProps {
  appointment: Appointment;
  onStatusChange: (id: string, status: string) => void;
}

export function AppointmentListItem({ appointment, onStatusChange }: AppointmentListItemProps) {
  return (
    <div className="border border-gray-200 rounded-lg p-4 hover:border-gray-300 transition-colors">
      <div className="flex items-center justify-between mb-3">
        <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(appointment.status)}`}>
          {appointment.status}
        </span>
        <span className="text-sm text-gray-500">
          {format(new Date(appointment.start_time), 'HH:mm')}
        </span>
      </div>

      <div className="space-y-2">
        <div className="flex items-center text-sm">
          <User className="h-4 w-4 text-gray-400 mr-2" />
          <span className="font-medium">{appointment.customers?.name}</span>
        </div>

        <div className="flex items-center text-sm">
          <Briefcase className="h-4 w-4 text-gray-400 mr-2" />
          <div>
            <span className="font-medium">{appointment.services?.name}</span>
            <span className="text-gray-500"> with {appointment.staff?.name}</span>
          </div>
        </div>
      </div>

      {appointment.notes && (
        <p className="mt-2 text-sm text-gray-500 border-t border-gray-100 pt-2">
          {appointment.notes}
        </p>
      )}

      <div className="mt-3 flex flex-wrap gap-2">
        {appointment.status === 'pending' && (
          <>
            <Button
              variant="secondary"
              onClick={() => onStatusChange(appointment.id, 'confirmed')}
            >
              Confirm
            </Button>
            <Button
              variant="secondary"
              onClick={() => onStatusChange(appointment.id, 'cancelled')}
            >
              Cancel
            </Button>
          </>
        )}
        {appointment.status === 'confirmed' && (
          <Button
            variant="secondary"
            onClick={() => onStatusChange(appointment.id, 'completed')}
          >
            Complete
          </Button>
        )}
      </div>
    </div>
  );
}