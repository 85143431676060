import React, { useEffect, useState } from 'react';
import { Users } from 'lucide-react';
import { api } from '../../../lib/api';
import type { Staff } from '../../../lib/types';

export function DashboardStaffList() {
  const [staff, setStaff] = useState<Staff[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadStaff();
  }, []);

  const loadStaff = async () => {
    try {
      const data = await api.staff.list();
      setStaff(data);
    } catch (err) {
      console.error('Failed to load staff:', err);
      setError('Failed to load staff members');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-sm text-red-600">{error}</p>
      </div>
    );
  }

  if (staff.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center py-12">
        <Users className="h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-500">No staff members added yet</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {staff.map((member) => (
        <div 
          key={member.id} 
          className="flex items-center justify-between p-4 rounded-lg border border-gray-200 hover:border-gray-300 transition-colors"
        >
          <div>
            <h3 className="font-medium text-gray-900">{member.name}</h3>
            <p className="text-sm text-gray-500">{member.role}</p>
          </div>
          <div className="text-sm text-gray-500">
            {member.email}
          </div>
        </div>
      ))}
    </div>
  );
}