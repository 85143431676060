import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../lib/api';
import { Calendar, Clock, MapPin, Phone, Mail, Briefcase } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import { Modal } from '../../components/ui/Modal';
import { BookingForm } from '../../components/portal/BookingForm';
import type { Business, Service, Staff } from '../../lib/api';

export function CustomerPortalPage() {
  const { businessId } = useParams();
  const [business, setBusiness] = useState<Business | null>(null);
  const [services, setServices] = useState<Service[]>([]);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [businessHours, setBusinessHours] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);

  useEffect(() => {
    if (!businessId) {
      setError('Business ID is required');
      setIsLoading(false);
      return;
    }
    loadBusinessDetails();
  }, [businessId]);

  const loadBusinessDetails = async () => {
    if (!businessId) return;
    
    try {
      const businessData = await api.businesses.getById(businessId);
      if (!businessData) {
        setError('Business not found');
        setIsLoading(false);
        return;
      }

      const [servicesData, staffData, hoursData] = await Promise.all([
        api.services.list(businessData.id),
        api.staff.list(businessData.id),
        api.businessHours.list(businessData.id)
      ]);

      setBusiness(businessData);
      setServices(servicesData);
      setStaff(staffData);
      setBusinessHours(hoursData);
    } catch (err) {
      setError('Failed to load business details');
    } finally {
      setIsLoading(false);
    }
  };

  const getDayName = (day: number) => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][day];
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
      </div>
    );
  }

  if (error || !business) {
    return (
      <div className="min-h-screen flex items-center justify-center px-4">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Business Not Found</h1>
          <p className="text-gray-600">The business you're looking for doesn't exist or is no longer available.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="text-center">
            <h1 className="text-3xl font-bold text-gray-900 sm:text-4xl">
              {business.business_name}
            </h1>
            <p className="mt-2 text-lg text-gray-500">
              Book your appointment online
            </p>
            <div className="mt-6">
              <Button onClick={() => setIsBookingModalOpen(true)}>
                Book Now
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Business Information */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Business Hours */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center mb-4">
              <Clock className="h-5 w-5 text-indigo-600" />
              <h2 className="ml-2 text-lg font-medium text-gray-900">Business Hours</h2>
            </div>
            <div className="space-y-2">
              {businessHours.map((hour) => (
                <div key={hour.day_of_week} className="flex justify-between text-sm">
                  <span className="text-gray-600">{getDayName(hour.day_of_week)}</span>
                  <span className="text-gray-900">
                    {hour.is_closed ? 'Closed' : `${hour.start_time} - ${hour.end_time}`}
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Location */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center mb-4">
              <MapPin className="h-5 w-5 text-indigo-600" />
              <h2 className="ml-2 text-lg font-medium text-gray-900">Location</h2>
            </div>
            <p className="text-gray-600">{business.address || 'Address not available'}</p>
          </div>

          {/* Contact Information */}
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center mb-4">
              <Phone className="h-5 w-5 text-indigo-600" />
              <h2 className="ml-2 text-lg font-medium text-gray-900">Contact</h2>
            </div>
            <div className="space-y-2">
              {business.phone && (
                <p className="flex items-center text-gray-600">
                  <Phone className="h-4 w-4 mr-2" />
                  {business.phone}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Our Services</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service) => (
            <div key={service.id} className="bg-white rounded-lg shadow p-6">
              <div className="flex items-center mb-3">
                <Briefcase className="h-5 w-5 text-indigo-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">{service.name}</h3>
              </div>
              {service.description && (
                <p className="text-gray-600 mb-3">{service.description}</p>
              )}
              <div className="flex justify-between items-center text-sm">
                <span className="text-gray-600">{service.duration} minutes</span>
                <span className="font-medium text-gray-900">${service.price}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Staff Section */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h2 className="text-2xl font-bold text-gray-900 mb-6">Our Team</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {staff.map((member) => (
            <div key={member.id} className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-medium text-gray-900">{member.name}</h3>
              <p className="text-gray-600">{member.role}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Booking Modal */}
      <Modal
        isOpen={isBookingModalOpen}
        onClose={() => setIsBookingModalOpen(false)}
        title="Book an Appointment"
      >
        <BookingForm
          business={business}
          onSuccess={() => setIsBookingModalOpen(false)}
          onCancel={() => setIsBookingModalOpen(false)}
        />
      </Modal>
    </div>
  );
}