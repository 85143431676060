import React from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { Input } from '../../components/ui/Input';
import { Button } from '../../components/ui/Button';
import { useAuthStore } from '../../store/auth';

export function ProfilePage() {
  const { user } = useAuthStore();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    // TODO: Implement profile update
    setIsLoading(false);
  };

  return (
    <DashboardLayout>
      <div className="max-w-2xl">
        <h1 className="text-2xl font-bold text-gray-900">Business Profile</h1>
        <p className="mt-1 text-sm text-gray-500">
          Manage your business information and settings.
        </p>

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <Input
            label="Business Name"
            defaultValue={user?.business_name}
            disabled={isLoading}
          />

          <Input
            label="Email Address"
            type="email"
            defaultValue={user?.email}
            disabled
          />

          <Input
            label="Phone Number"
            type="tel"
            defaultValue={user?.phone}
            disabled={isLoading}
          />

          <Button type="submit" isLoading={isLoading}>
            Save Changes
          </Button>
        </form>
      </div>
    </DashboardLayout>
  );
}