import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { Users } from 'lucide-react';
import { Button } from '../../components/ui/Button';
import { Modal } from '../../components/ui/Modal';
import { StaffForm } from '../../components/staff/StaffForm';
import { StaffList } from '../../components/staff/StaffList';
import { api } from '../../lib/api';
import { useAuthStore } from '../../store/auth';
import type { Staff } from '../../lib/types';

export function StaffPage() {
  const { user } = useAuthStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [staff, setStaff] = useState<Staff[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  const loadStaff = async () => {
    try {
      const data = await api.staff.list();
      setStaff(data);
    } catch (err: any) {
      console.error('Failed to load staff:', err);
      setError(err.message || 'Failed to load staff members');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadStaff();
  }, []);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this staff member?')) return;
    
    try {
      await api.staff.delete(id);
      await loadStaff(); // Reload staff list after deletion
    } catch (err: any) {
      console.error('Failed to delete staff member:', err);
      setError(err.message || 'Failed to delete staff member');
    }
  };

  const handleUpdate = async (id: string, data: Partial<Staff>) => {
    try {
      await api.staff.update(id, data);
      await loadStaff(); // Reload staff list after update
    } catch (err: any) {
      console.error('Failed to update staff member:', err);
      setError(err.message || 'Failed to update staff member');
    }
  };

  const handleSuccess = () => {
    setIsModalOpen(false);
    loadStaff(); // Reload staff list after adding new member
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Staff Management</h1>
            <p className="mt-1 text-sm text-gray-500">
              Add and manage your staff members
            </p>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>Add Staff Member</Button>
        </div>

        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
            {error}
          </div>
        )}

        {staff.length === 0 ? (
          <div className="bg-white shadow rounded-lg">
            <div className="p-6">
              <div className="flex flex-col items-center justify-center py-12">
                <Users className="h-12 w-12 text-gray-400" />
                <p className="mt-2 text-sm text-gray-500">No staff members added yet</p>
                <Button variant="secondary" className="mt-4" onClick={() => setIsModalOpen(true)}>
                  Add Your First Staff Member
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <StaffList 
            staff={staff} 
            onDelete={handleDelete}
            onUpdate={handleUpdate}
          />
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Add Staff Member"
      >
        <StaffForm
          onSuccess={handleSuccess}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </DashboardLayout>
  );
}