export type SubscriptionTier = 'basic' | 'standard' | 'premium';
export type SubscriptionStatus = 'active' | 'past_due' | 'canceled';

export interface SubscriptionLimits {
  maxBookings: number;
  maxStaff: number;
  warningThreshold: number;
}

export interface SubscriptionUsage {
  currentBookings: number;
  currentStaff: number;
  daysRemaining: number;
  periodStart: string;
  periodEnd: string;
}

export const SUBSCRIPTION_LIMITS: Record<SubscriptionTier, SubscriptionLimits> = {
  basic: {
    maxBookings: 100,
    maxStaff: 5,
    warningThreshold: 0.8 // 80%
  },
  standard: {
    maxBookings: Infinity,
    maxStaff: 15,
    warningThreshold: 0.8
  },
  premium: {
    maxBookings: Infinity,
    maxStaff: 50,
    warningThreshold: 0.9
  }
};