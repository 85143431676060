import { Building, Globe, Heart, Lock, Shield, Users } from 'lucide-react';

export const aboutFeatures = [
  {
    name: 'Established Excellence',
    description: 'Part of Global Voice Connect Solutions, a leader in business communication solutions.',
    icon: Building,
  },
  {
    name: 'Global Reach',
    description: 'Serving businesses across continents with localized booking solutions.',
    icon: Globe,
  },
  {
    name: 'Customer Focus',
    description: 'Dedicated to enhancing the booking experience for businesses and their clients.',
    icon: Heart,
  },
  {
    name: 'Trusted Security',
    description: 'Enterprise-grade security protecting your business and customer data.',
    icon: Shield,
  },
];

export const mainFeatures = [
  {
    name: 'Staff Management',
    description: "Manage your team's schedules, services, and availability in one place.",
    icon: Users,
  },
  {
    name: 'Secure Platform',
    description: 'Your data is protected with enterprise-grade security and regular backups.',
    icon: Lock,
  },
];