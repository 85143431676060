import { create } from 'zustand';
import { supabase } from '../lib/supabase';
import type { User } from '../types/auth';

interface AuthState {
  user: User | null;
  loading: boolean;
  error: string | null;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, profile: BusinessProfile) => Promise<void>;
  signOut: () => Promise<void>;
  initialize: () => Promise<void>;
}

interface BusinessProfile {
  businessName: string;
  ownerName: string;
  phone?: string;
  address?: string;
  subscriptionTier?: string;
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: true,
  error: null,
  signIn: async (email: string, password: string) => {
    try {
      set({ loading: true, error: null });

      const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (authError) throw authError;

      const { data: businessData, error: businessError } = await supabase
        .from('businesses')
        .select('*')
        .eq('auth_user_id', authData.user.id)
        .single();

      if (businessError && businessError.code !== 'PGRST116') {
        throw businessError;
      }

      set({ 
        user: {
          ...authData.user,
          business_name: businessData?.business_name,
          subscription_tier: businessData?.subscription_tier,
          subscription_status: businessData?.subscription_status
        },
        loading: false,
        error: null
      });
    } catch (error: any) {
      set({ loading: false, error: error.message });
      throw error;
    }
  },
  signUp: async (email: string, password: string, profile: BusinessProfile) => {
    try {
      set({ loading: true, error: null });

      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            business_name: profile.businessName,
            full_name: profile.ownerName,
          },
        },
      });
      
      if (authError) throw authError;
      if (!authData.user) throw new Error('Failed to create user account');

      const { data: businessData, error: businessError } = await supabase
        .from('businesses')
        .insert([{
          business_name: profile.businessName,
          phone: profile.phone || null,
          address: profile.address || null,
          timezone: 'UTC',
          subscription_tier: profile.subscriptionTier || 'basic',
          subscription_status: 'active',
          settings: {},
          auth_user_id: authData.user.id
        }])
        .select()
        .single();
      
      if (businessError) {
        console.error('Business creation error:', businessError);
        throw new Error('Failed to create business profile');
      }

      set({ 
        user: {
          ...authData.user,
          business_name: businessData.business_name,
          subscription_tier: businessData.subscription_tier,
          subscription_status: businessData.subscription_status
        },
        loading: false,
        error: null
      });

    } catch (error: any) {
      set({ loading: false, error: error.message });
      throw error;
    }
  },
  signOut: async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      set({ user: null, loading: false, error: null });
    } catch (error: any) {
      set({ loading: false, error: error.message });
      throw error;
    }
  },
  initialize: async () => {
    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (session?.user) {
        const { data: businessData, error: businessError } = await supabase
          .from('businesses')
          .select('*')
          .eq('auth_user_id', session.user.id)
          .single();

        if (businessError && businessError.code !== 'PGRST116') {
          throw businessError;
        }

        set({ 
          user: {
            ...session.user,
            business_name: businessData?.business_name,
            subscription_tier: businessData?.subscription_tier,
            subscription_status: businessData?.subscription_status
          },
          loading: false,
          error: null
        });
      } else {
        set({ user: null, loading: false, error: null });
      }
    } catch (error: any) {
      set({ user: null, loading: false, error: error.message });
    }
  },
}));