import { supabase } from '../supabase';
import { startOfDay, endOfDay } from 'date-fns';

export interface DashboardMetrics {
  totalAppointments: number;
  activeStaff: number;
  hoursBooked: number;
  revenue: number;
}

export async function fetchDashboardMetrics(): Promise<DashboardMetrics> {
  try {
    const today = new Date();
    const start = startOfDay(today);
    const end = endOfDay(today);

    // Get today's completed appointments
    const { data: appointments, error: appointmentsError } = await supabase
      .from('appointments')
      .select(`
        *,
        services (
          duration,
          price
        )
      `)
      .eq('status', 'completed')
      .gte('start_time', start.toISOString())
      .lte('end_time', end.toISOString());

    if (appointmentsError) throw appointmentsError;

    // Get active staff count
    const { data: staff, error: staffError } = await supabase
      .from('staff')
      .select('id')
      .eq('status', 'active');

    if (staffError) throw staffError;

    // Calculate metrics
    const totalHours = appointments?.reduce((acc, apt) => {
      const duration = apt.services?.duration || 0;
      return acc + (duration / 60);
    }, 0) || 0;

    const totalRevenue = appointments?.reduce((acc, apt) => {
      const price = apt.services?.price || 0;
      return acc + price;
    }, 0) || 0;

    return {
      totalAppointments: appointments?.length || 0,
      activeStaff: staff?.length || 0,
      hoursBooked: Math.round(totalHours * 10) / 10,
      revenue: totalRevenue
    };
  } catch (error) {
    console.error('Error fetching dashboard metrics:', error);
    throw error;
  }
}