import React, { useState } from 'react';
import { DashboardSidebar } from './DashboardSidebar';
import { DashboardHeader } from './DashboardHeader';
import { MobileNavigation } from '../layout/MobileNavigation';
import { MobileHeader } from '../layout/MobileHeader';

interface DashboardLayoutProps {
  children: React.ReactNode;
  title?: string;
}

export function DashboardLayout({ children, title = 'Dashboard' }: DashboardLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Desktop Header & Sidebar */}
      <div className="hidden lg:block">
        <DashboardHeader />
        <DashboardSidebar />
      </div>

      {/* Mobile Header & Navigation */}
      <MobileHeader 
        title={title}
        onMenuClick={() => setIsSidebarOpen(true)} 
      />
      <MobileNavigation />

      {/* Mobile Sidebar Overlay */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-gray-600 bg-opacity-75 z-50 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        >
          <div className="fixed inset-y-0 left-0 w-64 bg-white">
            <DashboardSidebar />
          </div>
        </div>
      )}

      {/* Main Content */}
      <div className="lg:pl-64 pt-16 lg:pt-16">
        <main className="mobile-main-content lg:p-8">
          {children}
        </main>
      </div>
    </div>
  );
}