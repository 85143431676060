import React, { useState } from 'react';
import { Input } from './Input';
import { Button } from './Button';
import { api } from '../../lib/api';

interface ServiceFormProps {
  onSuccess: () => void;
  onCancel: () => void;
}

interface FormData {
  name: string;
  description: string;
  duration: number;
  price: number;
}

export function ServiceForm({ onSuccess, onCancel }: ServiceFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState<FormData>({
    name: '',
    description: '',
    duration: 30,
    price: 0,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      await api.services.create({
        ...formData,
        business_id: business.id,
        status: 'active',
      });
      
      onSuccess();
    } catch (err: any) {
      setError(err.message || 'Failed to create service');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
          {error}
        </div>
      )}

      <Input
        label="Service Name"
        required
        value={formData.name}
        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
      />

      <Input
        label="Description"
        value={formData.description}
        onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
      />

      <Input
        label="Duration (minutes)"
        type="number"
        min="1"
        required
        value={formData.duration}
        onChange={(e) => setFormData(prev => ({ ...prev, duration: parseInt(e.target.value) }))}
      />

      <Input
        label="Price"
        type="number"
        min="0"
        step="0.01"
        required
        value={formData.price}
        onChange={(e) => setFormData(prev => ({ ...prev, price: parseFloat(e.target.value) }))}
      />

      <div className="flex justify-end space-x-4">
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" isLoading={isLoading}>
          Add Service
        </Button>
      </div>
    </form>
  );
}