import React, { useEffect, useState } from 'react';
import { Users, Calendar, Clock, TrendingUp } from 'lucide-react';
import { fetchDashboardMetrics } from '../../../lib/api/metrics';
import type { DashboardMetrics } from '../../../lib/api/metrics';

export function DashboardStats() {
  const [metrics, setMetrics] = useState<DashboardMetrics>({
    totalAppointments: 0,
    activeStaff: 0,
    hoursBooked: 0,
    revenue: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadMetrics();
  }, []);

  const loadMetrics = async () => {
    try {
      const data = await fetchDashboardMetrics();
      setMetrics(data);
    } catch (err) {
      console.error('Failed to load metrics:', err);
      setError('Failed to load metrics');
    } finally {
      setIsLoading(false);
    }
  };

  const stats = [
    { 
      name: 'Completed Appointments', 
      value: metrics.totalAppointments.toString(), 
      icon: Calendar,
      description: 'Today'
    },
    { 
      name: 'Active Staff', 
      value: metrics.activeStaff.toString(), 
      icon: Users,
      description: 'Currently active'
    },
    { 
      name: 'Hours Completed', 
      value: `${metrics.hoursBooked}h`, 
      icon: Clock,
      description: 'Today'
    },
    { 
      name: 'Revenue', 
      value: `$${metrics.revenue.toFixed(2)}`, 
      icon: TrendingUp,
      description: 'Today'
    },
  ];

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {[1, 2, 3, 4].map((i) => (
          <div key={i} className="bg-white overflow-hidden shadow rounded-lg p-5 animate-pulse">
            <div className="h-6 bg-gray-200 rounded w-1/2 mb-4"></div>
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md text-sm">
        {error}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <div key={stat.name} className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Icon className="h-6 w-6 text-indigo-600" />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      {stat.name}
                    </dt>
                    <dd className="text-lg font-semibold text-gray-900">
                      {stat.value}
                    </dd>
                    <dd className="text-xs text-gray-500">
                      {stat.description}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}