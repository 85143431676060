import { supabase } from './supabase';
import { startOfDay, endOfDay } from 'date-fns';
import type { Database } from './database.types';

type Tables = Database['public']['Tables'];
export type Business = Tables['businesses']['Row'];
export type Staff = Tables['staff']['Row'];
export type Service = Tables['services']['Row'];
export type BusinessHours = Tables['business_hours']['Row'];
export type Appointment = Tables['appointments']['Row'] & {
  service?: Service;
  staff?: Staff;
  customer?: { name: string; email: string };
};
export type Customer = Tables['customers']['Row'] & {
  appointments?: Appointment[];
};

export const api = {
  supabase,
  businesses: {
    get: async (): Promise<Business | null> => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;

      const { data: business, error } = await supabase
        .from('businesses')
        .select('*')
        .eq('auth_user_id', user.id)
        .single();
      
      if (error && error.code !== 'PGRST116') throw error;
      return business;
    },
    getById: async (id: string): Promise<Business | null> => {
      const { data: business, error } = await supabase
        .from('businesses')
        .select('*')
        .eq('auth_user_id', id)
        .single();
      
      if (error && error.code !== 'PGRST116') throw error;
      return business;
    },
    update: async (updates: Partial<Business>): Promise<Business> => {
      const { data, error } = await supabase
        .from('businesses')
        .update(updates)
        .eq('id', updates.id)
        .select()
        .single();

      if (error) throw error;
      return data;
    },
  },
  businessHours: {
    list: async (): Promise<BusinessHours[]> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('business_hours')
        .select('*')
        .eq('business_id', business.id)
        .order('day_of_week');

      if (error) throw error;
      return data || [];
    },
    upsert: async (hours: Partial<BusinessHours>[]): Promise<void> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { error } = await supabase
        .from('business_hours')
        .upsert(
          hours.map(hour => ({
            ...hour,
            business_id: business.id
          }))
        );

      if (error) throw error;
    },
  },
  appointments: {
    list: async (date: Date): Promise<Appointment[]> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const start = startOfDay(date);
      const end = endOfDay(date);

      const { data, error } = await supabase
        .from('appointments')
        .select(`
          *,
          service:services(*),
          staff:staff(*),
          customer:customers(name, email)
        `)
        .eq('business_id', business.id)
        .gte('start_time', start.toISOString())
        .lte('start_time', end.toISOString())
        .order('start_time');

      if (error) throw error;
      return data || [];
    },
    create: async (appointment: Partial<Appointment>): Promise<Appointment> => {
      const { data, error } = await supabase
        .from('appointments')
        .insert([appointment])
        .select(`
          *,
          service:services(*),
          staff:staff(*),
          customer:customers(name, email)
        `)
        .single();

      if (error) throw error;
      return data;
    },
    updateStatus: async (id: string, status: string): Promise<void> => {
      const { error } = await supabase
        .from('appointments')
        .update({ status })
        .eq('id', id);

      if (error) throw error;
    },
  },
  staff: {
    list: async (): Promise<Staff[]> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('staff')
        .select('*')
        .eq('business_id', business.id)
        .eq('status', 'active')
        .order('name');

      if (error) throw error;
      return data || [];
    },
    create: async (staff: Partial<Staff>): Promise<Staff> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('staff')
        .insert([{
          ...staff,
          business_id: business.id,
          status: 'active',
          working_hours: {}
        }])
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    delete: async (id: string): Promise<void> => {
      const { error } = await supabase
        .from('staff')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
  },
  services: {
    list: async (): Promise<Service[]> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('business_id', business.id)
        .eq('status', 'active')
        .order('name');

      if (error) throw error;
      return data || [];
    },
    create: async (service: Partial<Service>): Promise<Service> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('services')
        .insert([{
          ...service,
          business_id: business.id,
          status: 'active'
        }])
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    delete: async (id: string): Promise<void> => {
      const { error } = await supabase
        .from('services')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
  },
  customers: {
    list: async (): Promise<Customer[]> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('customers')
        .select(`
          *,
          appointments (
            id,
            start_time,
            status,
            services (name)
          )
        `)
        .eq('business_id', business.id)
        .order('name');

      if (error) throw error;
      return data || [];
    },
    findByEmail: async (email: string): Promise<Customer | null> => {
      const business = await api.businesses.get();
      if (!business) throw new Error('Business not found');

      const { data, error } = await supabase
        .from('customers')
        .select('*')
        .eq('business_id', business.id)
        .eq('email', email)
        .single();

      if (error && error.code !== 'PGRST116') throw error;
      return data;
    },
    create: async (customer: Partial<Customer>): Promise<Customer> => {
      const { data, error } = await supabase
        .from('customers')
        .insert([customer])
        .select()
        .single();

      if (error) throw error;
      return data;
    },
  },
};