import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../../components/dashboard/DashboardLayout';
import { SubscriptionUsagePanel } from '../../components/subscription/SubscriptionUsagePanel';
import { SubscriptionPlansPanel } from '../../components/subscription/SubscriptionPlansPanel';
import { useAuthStore } from '../../store/auth';
import { fetchSubscriptionUsage } from '../../lib/api/subscription';
import type { SubscriptionUsage } from '../../types/subscription';

const STRIPE_PAYMENT_LINKS = {
  standard: 'https://buy.stripe.com/28obL44Ntg7pbxC9B1',
  premium: 'https://buy.stripe.com/eVa02mbbRbR945abJa'
};

export function SubscriptionPage() {
  const { user } = useAuthStore();
  const [usage, setUsage] = useState<SubscriptionUsage | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadUsage();
  }, []);

  const loadUsage = async () => {
    try {
      const data = await fetchSubscriptionUsage();
      setUsage(data);
    } catch (err) {
      console.error('Failed to load subscription usage:', err);
      setError('Failed to load subscription data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgrade = (plan: 'standard' | 'premium') => {
    const stripeLink = STRIPE_PAYMENT_LINKS[plan];
    if (stripeLink) {
      window.open(`${stripeLink}?redirect_url=${window.location.origin}/dashboard/subscription`, '_blank');
    }
  };

  if (isLoading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600" />
        </div>
      </DashboardLayout>
    );
  }

  if (error || !usage || !user?.subscription_tier) {
    return (
      <DashboardLayout>
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-md">
          {error || 'Unable to load subscription information'}
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="space-y-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Subscription</h1>
          <p className="mt-1 text-sm text-gray-500">
            Manage your subscription and monitor usage
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <SubscriptionUsagePanel 
            tier={user.subscription_tier} 
            usage={usage}
          />
          
          <SubscriptionPlansPanel 
            currentTier={user.subscription_tier}
            onUpgrade={handleUpgrade}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}